import React, { useEffect, useState, useContext } from 'react';
import Button from '../Button';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';

const CardSlider = dynamic(() => import('../CardSlider'));

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './CardListLocals.module.scss';
import classNames from 'classnames';
import { trackCtaClickStartpage, trackEvent } from '../../utils/datalayer';
import { TrackingContext } from '../StreamField/StreamField';

const CardListLocals = ({ title, preamble, link, items, modifiers }) => {
    const [currentHref, setHref] = useState(null);
    const isStartpage = useContext(TrackingContext) !== null;

    useEffect(() => {
        setHref(window.location.href);
    }, []);

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    let containerClasses = '';
    if (items) {
        containerClasses = classNames(
            modifiers.map((x) => ' ' + x),
            styles['CardListLocals'],
            {
                [styles['CardListLocals--isVisible']]: inView,
                [styles['CardListLocals--SpaceBetween']]: items.length > 3,
            }
        );
    }

    let allLinkText = link?.label || i18n.t('CardListLocals.allLinkText');

    return (
        <div className={containerClasses} ref={ref}>
            <div className={styles['CardListLocals__IntroContainer']}>
                {title && (
                    <h2 className={styles['CardListLocals__Title']}>{title}</h2>
                )}

                {!!preamble && (
                    <p className={styles['CardListLocals__Preamble']}>
                        {preamble}
                    </p>
                )}
            </div>

            {items && (
                <div
                    className={classNames(
                        styles['CardListLocals__CardWrapper']
                    )}
                >
                    <div className={styles['CardListLocals__CardContainer']}>
                        <CardSlider
                            items={items}
                            visibleSlides={4}
                            content={'locals'}
                            onCardClick={() => trackEvent('localBannerClick')}
                        />
                    </div>
                </div>
            )}

            {link && link.href && link.href !== currentHref && (
                <div className={styles['CardListLocals__LinkContainer']}>
                    <Button
                        className="Button"
                        modifiers={[styles['CardListLocals__Button']]}
                        type="primary"
                        href={link.href}
                        text={allLinkText}
                        onClick={() => {
                            trackEvent('localCtaClick');
                            if(isStartpage) {
                                trackCtaClickStartpage();
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
};

CardListLocals.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    items: PropTypes.array,
    link: PropTypes.object,
    modifiers: PropTypes.array,
};

CardListLocals.defaultProps = {
    title: null,
    preamble: null,
    items: null,
    link: null,
    modifiers: [],
};

export default CardListLocals;
